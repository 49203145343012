
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import usersApi from "@/core/services/UsersApi";

export default defineComponent({
    name: "sign-in",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const submitButton = ref<HTMLElement | null>(null);

        //Create form validation object
        const login = Yup.object().shape({
            email: Yup.string().email().required().label("Email"),
            password: Yup.string().min(4).required().label("Password"),
        });

        //Form submit function
        const onSubmitLogin = (values) => {
            // Clear existing errors
            //store.dispatch(Actions.LOGOUT);

            if (submitButton.value) {
                // Activate indicator
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            // Dummy delay
            setTimeout(() => {
                // Send login request
                store
                    .dispatch(Actions.LOGIN, values)
                    .then((res) => {
                        if (res.user.permission.position === 5) {
                            if (res.user.member_id) {
                                router.push({ name: "member-update", params: { member_id: res.user.member_id } });
                            } else {
                                Swal.fire({
                                    text: "Your account does not have the rights to access to the backoffce. Please contact the administrator.",
                                    icon: "error",
                                    buttonsStyling: false,
                                    confirmButtonText: "Ok",
                                    customClass: {
                                        confirmButton: "btn fw-bold btn-light-danger",
                                    },
                                });
                            }
                        } else {
                            router.push({ name: "members-list" });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Swal.fire({
                            text: "No active account found with the provided credentials. Either your account wasn't activated or you didn't have any email registered to it. Please contact the administrator to receive an activation link.",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Try again!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                //Deactivate indicator
                submitButton.value?.removeAttribute("data-kt-indicator");
            }, 500);
        };

        return {
            onSubmitLogin,
            login,
            submitButton,
            router,
        };
    },

    methods: {
        redirectChangePassword() {
            this.router.push({ name: "change-password" });
        },
    },
});
